body{
  padding: 0;
  margin: 0;
  background: #6cbbac;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
}
a{
  text-decoration: none;
  color: teal;
}
.page{
  background: #efefef;
}
header{
  text-align: center;
  padding: 15px 0;
}
header .menu{
  display: flex;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  justify-content: center;
}
header .menu > *{
  padding: 5px 15px;
  font-weight: 700;
}
footer{
  background: #6cbbac;
  color: #1f5148;
  font-size: 14px;
  text-align: center;
  padding: 20px 0;
}
footer .sitemap{}
footer .sitemap a{
  margin: 0 10px;
}

.container{
  padding: 0 20px 20px;
}

.pokemons{}
.pokemons .list{
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 10px 0;
  box-shadow: 2px 5px 10px -10px #666;
}
.pokemons .list .link{
  cursor: pointer;
  display: block;
  padding: 15px;
  display: flex;
  align-items: center;
}
.pokemons .list .link:hover{
  color: teal;
}
.pokemons .list .link .name{
  flex: 1;
}
.pokemons .list .link .own{
  font-size: 12px;
  color: #1f5148;
}

.pokemons-detail{
  text-align: center;
}
.pokemons-detail .foto{}
.pokemons-detail .nama{
  font-size: 30px;
  font-weight: 300;
  text-transform: capitalize;
}
.pokemons-detail .catch{
  background: coral;
  color: #fff;
  border-radius: 4px;
  padding: 5px 0;
  cursor: pointer;
}
.pokemons-detail .save{
  background: darkcyan;
  color: #fff;
  border-radius: 4px;
  padding: 5px 0;
  cursor: pointer;
}
.pokemons-detail .stats{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 15px 0;
}
.pokemons-detail .stats .stats-item{
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  padding: 5px 0;
  flex-basis: 49%;
}
.pokemons-detail .stats .stats-item:nth-child(2n){
  border-left: none;
}
.pokemons-detail .stats label{
  font-weight: 700;
  font-size: 12px;
}
.pokemons-detail .toaster{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: red;
  color: #fff;
  padding: 15px 0;
}

.pokemon-form{
  padding: 15px 0;
}
.pokemon-form form{
  display: flex;
  flex-direction: column;
  padding: 10px 0 0;
}
.pokemon-form form input[type="text"]{
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 0 0 5px;
  padding: 15px 10px;
}
.pokemon-form form input[type="submit"]{
  background: darkcyan;
  color: #fff;
  border-radius: 4px;
  padding: 10px 0;
  cursor: pointer;
  border: none;
}

.alert{
  border: 1px solid #ccc;
  padding: 15px 10px;
  border-radius: 5px;
}
.alert.alert-notif{
  background: antiquewhite;
  color: #666;
}
.alert.alert-success{
  background: #b3e6da;
  color: #3b898a;
}
.alert.alert-success{
  background: #b3e6da;
  color: #3b898a;
}
.alert.alert-red{
  background: indianred;
  color: #fff;
}

.pokemon-loader{}
.pokemon-loader .top{
  height: 200px;
  background: #ddd;
}
.pokemon-loader .mid{
  height: 50px;
  background: #ddd;
  margin: 10px 0;
}
.pokemon-loader .btm{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: -2px;
}
.pokemon-loader .btm .btm-l{
  margin: 1px;
  flex-basis: 49%;
  height: 50px;
  background: #ddd;
}

.pokemon-loader-home{}
.pokemon-loader-home .h{
  background: #ddd;
  margin: 5px 0;
  height: 50px;
}

.my-pokemon{}
.my-pokemon nav{
  display: inline-block;
  padding: 5px;
  margin: 10px 0;
  box-sizing: border-box;
  text-decoration: none;
  font-weight: 700;
  color: #fff;
  background: tomato;
  box-shadow: inset 0 -0.6em 0 -0.35em rgba(0,0,0,0.17);
  text-align: center;
  position: relative;
  cursor: pointer;
}
.my-pokemon nav.release-one{
  display: inline-block;
}
.my-pokemon nav.release-all{
  display: block;
}

.my-pokemon-list{
  padding: 0 0 20px;
}
.my-pokemon-list h1{
  text-align: center;
  font-weight: 300;
}
.my-pokemon-list .i{
  background: tomato;
  border-radius: 5px;
  padding: 10px 25px;
  background: #e3e3e3;
  margin: 15px 0;
  box-shadow: 2px 5px 10px -10px #000;
}
.my-pokemon-list .i .name{
  font-weight: 300;
  font-size: 20px;
}
.my-pokemon-list .i .nickname{
  font-weight: 700;
  font-size: 14px;
}

.pokemon-home{}
.pokemon-home nav{
  display: block;
  padding: 10px;
  margin: 30px 0 10px 0;
  box-sizing: border-box;
  text-decoration: none;
  font-weight: 700;
  color: #fff;
  background: teal;
  box-shadow: inset 0 -0.6em 0 -0.35em rgba(0,0,0,0.17);
  text-align: center;
  position: relative;
  cursor: pointer;
}
.pokemon-home nav:hover{
  background: indianred;
}
.pokemon-home .pager{
  display: flex;
  margin: -5px;
  padding: 15px 0;
}
.pokemon-home .pager nav{
  flex: 1;
  margin: 5px;
}

.custom-404{}
.custom-404 img{
  width: 100%;
  object-fit: cover;
}